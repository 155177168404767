import { DefaultPrivacyLevel, datadogRum } from '@datadog/browser-rum'

export function useLogger() {
    type modes = 'development' | 'staging' | 'production'
    const env = import.meta.env.MODE as modes
    const logLevels = {
        development: 6,
        staging: 2,
        production: 2,
    }

    return {
        log: (...args: any) => {
            if ((logLevels[env] || 0) >= 6) {
                console.log(...args)
            }
        },
        trace: (...args: any) => {
            if ((logLevels[env] || 0) >= 5) {
                console.trace(...args)
            }
        },
        debug: (...args: any) => {
            if ((logLevels[env] || 0) >= 4) {
                console.debug(...args)
            }
        },
        info: (...args: any) => {
            if ((logLevels[env] || 0) >= 3) {
                console.info(...args)
            }
        },
        warn: (...args: any) => {
            if ((logLevels[env] || 0) >= 2) {
                console.warn(...args)
            }
        },
        error: (...args: any) => {
            if ((logLevels[env] || 0) >= 1) {
                console.error(...args)
            }
        },
        fatal: (...args: any) => {
            if ((logLevels[env] || 0) >= 0) {
                console.error(...args)
            }
        },
    }
}

export function useBrowserLogs() {
    if (!window.DD_RUM.getInternalContext()) {
        const shouldTrack = ['production', 'staging', 'testing'].includes(import.meta.env.VITE_APP_DATADOG_ENVIRONMENT)
        const options = {
            allowedTracingUrls: [(url: string) => url.startsWith(import.meta.env.VITE_URL_PINPOINT)],
            applicationId: 'aaa34b03-b2cb-4282-913e-41879fc90844',
            clientToken: 'pub422cef1b9c1c56a4d1b16d04ed61a27c',
            defaultPrivacyLevel: 'allow' as DefaultPrivacyLevel,
            site: 'datadoghq.com',
            service: 'pinpoint-fe',
            env: import.meta.env.VITE_APP_DATADOG_ENVIRONMENT || 'development',
            forwardConsoleLogs: 'all',
            sessionReplaySampleRate: shouldTrack ? 100 : 0,
            sessionSampleRate: shouldTrack ? 100 : 0,
            trackLongTasks: shouldTrack,
            trackResources: shouldTrack,
            trackUserInteractions: shouldTrack,
            version: 'pinpoint_3',
        }

        datadogRum.init(options)
        datadogRum.startSessionReplayRecording()
    }

    return datadogRum
}

export default useLogger()
